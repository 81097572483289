<template>
  <div class="parents">
    <el-tree
      class="left"
      :data="treedata"
      :props="defaultProps"
      @node-click="handleNodeClick"
      default-expand-all
    ></el-tree>

    <div class="right">
      <div class="top">
        <el-input
          class="input"
          v-model="form.name"
          placeholder="请输入用户名称"
        ></el-input>
        <el-button class="leftgap" type="primary" @click="loadArticles()"
          >查询</el-button
        >
        <el-button
          class="leftgap"
          type="primary"
          @click="add_edit_user('add', null)"
          >新增</el-button
        >
        <el-button class="leftgap" type="primary" @click="research()"
          >重置</el-button
        >
      </div>
      <div class="bottom">
        <el-table
          :data="tabledata"
          stripe
          class="list-table"
          height="100%"
          style="width: 100%"
          size="mini"
        >
          <el-table-column
            type="index"
            min-width="300"
            label="序号"
          ></el-table-column>
          <el-table-column prop="account" label="账号"> </el-table-column>
          <el-table-column prop="name" label="用户名"> </el-table-column>
          <el-table-column prop="deptInfos[0].deptName" label="所属部门">
          </el-table-column>
          <el-table-column prop="deptInfos[0].postName" label="职位">
          </el-table-column>
          <el-table-column prop="roleInfos[0].roleName" label="角色名称">
          </el-table-column>
          <el-table-column width="180" label="操作" fixed="right">
            <template slot-scope="scope">
              <div>
                <el-tooltip
                  content="编辑"
                  placement="top"
                  :open-delay="0"
                  :enterable="false"
                >
                  <el-button
                    size="mini"
                    @click="add_edit_user('edit', scope.row)"
                    circle
                    icon="el-icon-edit"
                    type="primary"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  content="重置密码"
                  placement="top"
                  :open-delay="0"
                  :enterable="false"
                >
                  <el-button
                    size="mini"
                    @click="resetpsd(scope.row)"
                    circle
                    icon="el-icon-help"
                    type="primary"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  content="删除"
                  placement="top"
                  :open-delay="0"
                  :enterable="false"
                >
                  <el-button
                    size="mini"
                    @click="showdetaile(scope.row)"
                    circle
                    icon="el-icon-delete"
                    type="primary"
                  ></el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          layout="prev, pager, next"
          background
          :total="totalCount"
          :page-size="form.size"
          :current-page.sync="form.pages"
          @current-change="onCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      class="form-panel"
      title="你确定删除此员工吗"
      :visible.sync="delectalartshow"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="300px"
    >
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delectalartshow = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="delectdept"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑、添加 -->
    <el-dialog
      class="form-panel"
      :title="panelTitle"
      :visible.sync="showadd_edit"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="800px"
      @closed="CloseFormPanel"
    >
      <el-form
        ref="userEditForm"
        :model="formPanelData"
        class="user-edit-form"
        label-width="110px"
        label-position="right"
        :rules="formPanelOptionsRules"
        @submit.native.prevent
      >
        <el-form-item
          v-for="(item, index) in formPanelOptions"
          :key="index + item.key"
          class="edit-form-item"
          :label="item.label + '：'"
          :prop="item.key"
          size="small"
        >
          <div v-if="item.type === 'text'" class="edit-form-text">
            <el-input
              :id="'edit' + item.key + index"
              v-model="formPanelData[item.key]"
              :type="item.type"
              :placeholder="'请输入' + item.label"
              clearable
              size="small"
              autocomplete="off"
            />
          </div>
          <div v-if="item.type === 'cascade'" class="edit-form-select">
            <el-cascader
              :key="item.key"
              style="width: 100%"
              :id="'edit' + item.key"
              v-model="selectdept"
              :type="item.type"
              :options="treedata"
              :props="{
                checkStrictly: true,
                label: 'name',
                value: 'deptId',
                checkStrictly: true,
              }"
              :placeholder="'请选择' + item.label"
              clearable
              @change="handleSelectDept"
            />
          </div>
        </el-form-item>
        <el-form-item
          class="edit-form-item"
          label="职位："
          prop="postid"
          size="small"
        >
          <el-select
            style="width: 100%"
            v-model="formPanelData.postIds"
            placeholder="请先选择部门，后选择职位"
          >
            <el-option
              v-for="items in postIdsList"
              :key="items.postId"
              :label="items.name"
              :value="items.postId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="edit-form-item"
          label="角色："
          prop="roleId"
          size="small"
        >
          <el-select
            style="width: 100%"
            v-model="formPanelData.roleIds"
            placeholder="请选择角色"
          >
            <el-option
              v-for="items in roleIdsList"
              :key="items.roleId"
              :label="items.name"
              :value="items.roleId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="info" @click="CloseFormPanel"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="submitEditInfo"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getdeptlisturl,
  getuserlist,
  getupostlist,
  getrolelistUrl,
  adduserurl,
  deluserurl,
  resetpadurl,
  edituserurl,
} from "@/api/jurisdiction";
import { translateDataToTree } from "@/utils/setMethods";
export default {
  data() {
    const regPhone = (str) => /^\d{11}$/.test(str);
    const validatePhone = (rule, value, callback) => {
      if (!regPhone(value)) {
        callback(new Error("请输入11位手机号码"));
      } else {
        callback();
      }
    };
    return {
      selectdept: "",
      postIdsList: [],
      roleIdsList: [],
      treeData: [],
      formPanelData: {},
      formPanelOptions: [
        { type: "text", label: "用户名", key: "name" },
        { type: "text", label: "账号", key: "account", editUnshow: "true" },
        { type: "text", label: "手机号码", key: "phoneNumber" },
        {
          type: "cascade",
          label: "部门",
          key: "deptName",
          placeholder: "请选择部门",
        },
      ],
      formPanelOptionsRules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        phoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: validatePhone, trigger: "blur" },
        ],
        deptName: [{ required: true, message: "请选择部门", trigger: "blur" }],
        postid: [{ required: true, message: "请选择职位", trigger: "blur" }],
        roleId: [{ required: true, message: "请选择角色", trigger: "blur" }],

        // deptInfos: [
        //   {
        //     deptId: [
        //       { required: true, message: "请选择部门", trigger: "change" },
        //     ],
        //     postId: [
        //       { required: true, message: "选择职位", trigger: "change" },
        //     ],
        //   },
        // ],
      },
      delectalartshow: false,
      panelTitle: null,
      form: {
        name: null,
        size: 20,
        page: 1,
        deptId: 1,
      },
      treedata: [],
      totalCount: 0,
      tabledata: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      showadd_edit: false,
    };
  },
  mounted() {
    this.getdeptlist();
    this.loadArticles(1);
    this.getrolelist();
  },
  methods: {
    resetpsd(row) {
      this.$confirm("将初始化此账号的密码，是否继续", "警告", {
        type: "warning",
        confirmButtonText: "确定",
        closeOnClickModal: false, // 点击model背景层不完成MessageBox
      }).then(() => {
        resetpadurl({ userId: row.userId }).then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.loadArticles();
            this.$message({
              message: "成功",
              type: "success",
            });
          }
        });
      });
    },
    showdetaile(row) {
      this.$confirm("将删除该数据，是否继续", "警告", {
        type: "warning",
        confirmButtonText: "确定",
        closeOnClickModal: false, // 点击model背景层不完成MessageBox
      }).then(() => {
        deluserurl({ userId: row.userId }).then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.loadArticles();
            this.$message({
              message: "成功",
              type: "success",
            });
          }
        });
      });
    },
    async submitEditInfo() {
      try {
        let res;
        let a = [];
        a.push(this.formPanelData.postIds);
        this.formPanelData.postIds = a;
        let b = [];
        b.push(this.formPanelData.roleIds);
        this.formPanelData.roleIds = b;
        if (this.panelTitle === "新增用户") {
          res = await adduserurl(this.formPanelData);
        } else if (this.panelTitle === "编辑用户") {
          res = await edituserurl(this.formPanelData);
        }

        if (res.data.code === 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.research();
        this.showadd_edit = false;
      }
    },
    async getrolelist() {
      try {
        let res = await getrolelistUrl();
        if (res.data.code === 200) {
          this.roleIdsList = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    add_edit_user(type, row) {
      console.log(row);
      this.formPanelData = {};
      if (type === "add") {
        this.panelTitle = "新增用户";
      } else if (type === "edit") {
        this.panelTitle = "编辑用户";
        this.formPanelData = row;
      }
      this.showadd_edit = true;
    },

    CloseFormPanel() {
      this.showadd_edit = false;
    },

    research() {
      this.form.name = null;
      this.loadArticles();
    },
    showdelect(row, column, event) {
      this.delectalartshow = true;
    },
    async delectdept() {},

    async getdeptlist() {
      try {
        const res = await getdeptlisturl();
        if (res.data.code === 200) {
          this.treedata = translateDataToTree(res.data.data);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },

    async handleSelectDept(val) {
      if (val) {
        this.selectdept = val[val.length - 1];
      }
      try {
        const res = await getupostlist({ deptId: this.selectdept });
        if (res.data.code === 200) {
          this.postIdsList = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    handleNodeClick(data) {
      console.log(data);
      this.loadArticles(null, data.deptId);
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },
    async loadArticles(page = 1, deptId = null) {
      this.form.page = page;
      this.form.deptId = deptId;
      // 展示加载中 loading
      this.loading = true;
      try {
        const res = await getuserlist(this.form);
        if (res.data.code === 200) {
          this.tabledata = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.parents {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .left {
    border-style: solid;
    border-width: 1px;
    border-color: #6f716a;
    background-color: white;
    width: 15%;
    height: 100%;
  }
  .right {
    background-color: white;
    border-color: #6f716a;
    border-style: solid;
    border-width: 1px;
    width: 83%;
    height: 100%;
    padding-left: 10px;
    padding-right: 5px;
    .top {
      padding-top: 10px;
      padding-bottom: 10px;
      border-width: 0px, 0px, 0px, 0px;
      border-bottom-color: #6f716a;
      border-bottom-style: solid;
      .input {
        height: 20%;
        width: 200px;
        margin-right: 10px;
      }
    }
    .bottom {
      height: 80%;
    }
  }
}
.edit-form {
  display: flex;
  flex-wrap: wrap;
  .edit-form-text {
    width: 260px;
  }
  .edit-form-select {
    width: 260px;
  }
  .edit-form-switch {
    width: 280px;
  }
  .deptInfos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px dotted #ddd;
  }
  .regulation-btn {
    z-index: 1;
    span {
      cursor: pointer;
      font-size: 20px;
      color: #20a0ff;
      &:first-child {
        margin-left: -50px;
        margin-right: 10px;
      }
    }
  }
}
</style>
